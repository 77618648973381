<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<h1>Edit</h1>
				<div v-if="currentItem" class="edit-form">
					<Form @submit="updateItem" :validation-schema="schema">
						<div class="form-floating mb-3">
							<Field name="title" type="text" id="title" class="form-control" v-model="currentItem.title" />
							<label for="title">Title</label>
							<ErrorMessage name="title" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field
								name="date"
								type="date"
								id="date"
								class="form-control"
								:value="this.$filters.formatDate(this.currentItem.date)"
								@input="this.currentItem.date = $event.target.value"
							/>
							<label for="date">Date</label>
							<ErrorMessage name="date" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="mb-3 d-flex">
							<button class="btn btn-primary me-auto" :disabled="updateProgess">
								<span v-show="updateProgess" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
								<span>Update</span>
							</button>
							<a class="btn btn-primary me-2" @click="confirmDelete">Delete</a>
							<router-link class="btn btn-primary" to="/items">Back</router-link>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>

	<confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
</template>

<script>
import ConfirmDialogue from '../components/_ConfirmDialogue.vue';
import ItemService from '../services/item.service';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';

export default {
	name: 'AppItemEdit',
	components: {
		Form,
		Field,
		ErrorMessage,
		ConfirmDialogue,
	},
	data() {
		const schema = yup.object().shape({
			title: yup.string().min(3, 'Please enter more than 3 character').required('Title is required!'),
		});

		return {
			currentItem: null,
			updateProgess: false,
			schema,
		};
	},
	methods: {
		async confirmDelete() {
			const ok = await this.$refs.confirmDialogue.show({
				message: 'Are you sure you want to delete this item? It cannot be undone.',
				okButton: 'Yes',
				cancelButton: 'No',
			});

			if (ok) {
				this.deleteItem();
			}
		},
		getItem(id) {
			ItemService.get(id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.currentItem = response.data.result;
					} else {
						this.currentItem = null;
						this.$toast.warning(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		updateItem() {
			this.updateProgess = true;
			ItemService.update(this.currentItem.id, this.currentItem)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.$toast.success(response.data.success.message);
					} else {
						this.$toast.info(response.data.error.message);
					}
					this.updateProgess = false;
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		deleteItem() {
			ItemService.delete(this.currentItem.id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.$router.push('/items');
					} else {
						this.$toast.info(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		catchError(e) {
			// Check if not authorized, if not show error message
			if (e.response.status === 401) {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			} else {
				this.updateProgess = false;
				console.log(e);
			}
		},
	},
	computed: {},
	created() {
		this.getItem(this.$route.params.id);
	},
	mounted() {},
};
</script>
